<template>
	<div>
		<div class='a-itinerary'>
			<a-itinerary-header :name_tour='name_tour' />

			<div class='app-container'>
				<div class='a-itinerary-wrap'
					:class='{"a-itinerary-wrap-center": !(order.items && order.items.length)}'
				>
					<a-itinerary-content
						:order='order'
						:name_tour='name_tour'
						@input='name_tour = $event,
						changeTitle()'
						@update-order="order = $event"
						@update-tourists='getOrder'
						:isCharterFlight='isCharterFlight'
					/>
					<div class='a-itinerary-offset' v-if='order.items && order.items.length'></div>
					<a-itinerary-info
                        :price='order.price'
                        :orderUpdatedAt='order.updatedAt'
                        :isCharterFlight='isCharterFlight'
                        :status='order.status'
                        :crmOrderId='order.crmOrderId'
                        @open-dialog='$event => dialog = $event'
                        @set-order='setOrder'
                        @get-order='getOrder'
                        v-if='order.items && order.items.length'
					/>
					<a-dialog-next v-model='dialog' @close-dialog="dialog = false" />
				</div>
				<div class='tablet-show' v-if='isCharterFlight'>
					<a-option-details />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import AItineraryHeader from '@/views/account/itinerary/header';
	import AItineraryContent from '@/views/account/itinerary/content';
	import AItineraryInfo from '@/views/account/itinerary/info';
	import ADialogNext from '@/views/account/itinerary/dialogs/next';
	import AOptionDetails from '@/views/account/itinerary/option-details';
	import { getOrder } from '@/services/request';

	export default {
        components: {
            AItineraryHeader,
            AItineraryContent,
            AItineraryInfo,
            ADialogNext,
            AOptionDetails
        },
		data: () => ({
			order: {},
			name_tour: '',
			dialog: false
		}),
		computed: {
			isCharterFlight() {
				return this.order.items ? this.order.items.some(el => el.type == 'charterFlight') : false;
			}
		},
		created() {
			this.getOrder();
		},
		methods: {
			getOrder() {
				getOrder(this.$route.params.orderId).then(res => {
					this.order = res.data;
					this.name_tour = res.data.name;
					this.changeTitle();
				})
			},
            setOrder(data) {
                this.order = data;
            },
			changeTitle() {
				document.title = (this.name_tour && this.name_tour.length ? this.name_tour : this.$t("account.itinerary.content.name_tour")) + ' - ' + process.env.VUE_APP_TITLE;
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-itinerary {
		padding-top: 60px;
		@include minw( $grid-breakpoints-sm ) {
			padding-top: 75px;
		}
		&-offset {
			padding-right: 150px * .6;
			@include minw( $grid-breakpoints-md ) {
				padding-right: 150px * .8;
			}
			@include minw( $grid-breakpoints-lg ) {
				padding-right: 150px;
			}
		}
		&-wrap {
			display: flex;
			align-items: flex-start;
			flex-direction: column-reverse;
			padding-bottom: 60px;
			@include minw( $grid-breakpoints-sm ) {
				padding: 65px * .8 0 100px * .8;
				flex-direction: row;
			}
			@include minw( $grid-breakpoints-md ) {
				padding: 65px 0 100px;
			}
			&-center {
				justify-content: center;
			}
		}
	}
</style>
