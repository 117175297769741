<template>
	<div class='a-itinerary-info'>
		<div class='a-itinerary-info-wrap'>
			<div class='a-itinerary-info-box'>
                <div class="row a-itinerary-info-box-row">
                    <div class="col">
                        <p class='a-itinerary-info-title'>{{$t("account.itinerary.info.the_total_cost")}}</p>
                        <p class='a-itinerary-info-total'>{{$helpers.amount(price.total, price.currency)}}</p>
                        <p class='a-itinerary-info-descr'>{{$t("account.itinerary.markup")}}: {{$helpers.amount(price.margin, price.currency)}}</p>
                        <p class='a-itinerary-info-descr'>{{$t("account.itinerary.amount_paid")}}: {{$helpers.amount(price.payment, price.currency)}}</p>
                    </div>
                    <template v-if="status.code === 'draft'">
                        <div class="col-auto a-itinerary-info-box-row-col" v-if='timer && time > 0'>
                            <p class='a-itinerary-info-action-timer orange--text'>
                                <v-icon icon='timer' size='12' />
                                <span>{{timeDiffAbbr(time)}}</span>
                            </p>
                        </div>
                    </template>
                </div>


				<div class='a-itinerary-info-group-buttons'>
                    <template v-if="status.code === 'draft'">
                        <div class='a-itinerary-info-button'>
                            <v-button xxsmall color="green white--text" @click='toBook' v-if="time > 0">{{$t("account.buttons.continue")}}</v-button>
                            <v-button xxsmall color="green white--text" :loading="loading" @click='updateServices' v-else>{{$t("account.buttons.update")}}</v-button>
                        </div>
                        <div class='a-itinerary-info-button block' @click='addToSuitcase'>
                            <v-button block outline xxsmall color="green">{{$t("account.itinerary.info.save_suitcase")}}</v-button>
                        </div>
                    </template>
                    <template v-if="crmOrderId">
                        <div class='a-itinerary-info-button block'>
                            <v-button block outline xxsmall color="green" @click="toCrm">{{$t("account.buttons.view")}}</v-button>
                        </div>
                    </template>
                </div>
			</div>
            <div class='a-itinerary-info-status' :style="'background-color: ' + status.color" v-if="status.code !== 'draft'">
                <p class='a-itinerary-info-status-title'>{{ status.name }}</p>
            </div>
		</div>

		<div class='tablet-hide' v-if='isCharterFlight'>
			<a-option-details />
		</div>
	</div>
</template>

<script>
	import AOptionDetails from '@/views/account/itinerary/option-details';
	import timeDiffAbbrMixin from '@/mixins/time-diff-abbr';
	import { addToSuitcase, toBook, updateServices } from '@/services/request';

	export default {
        components: {
            AOptionDetails
        },
		props: ['price', 'isCharterFlight', 'orderUpdatedAt', 'status', 'crmOrderId'],
        mixins: [timeDiffAbbrMixin],
        data: () => ({
            time: null,
            timer: null,
            loading: false,
            updatedAt: 0
        }),
        created() {
            this.initUpdatedAt();
            this.startTime();
        },
        watch:{
            orderUpdatedAt(){
                this.initUpdatedAt();
                this.startTime();
            },
        },
		methods: {
            toCrm(){
                this.$router.push({
                    name: "my-order",
                    params: {id: this.crmOrderId, lang: this.$route.params.lang}
                })
            },
            initUpdatedAt(){
                this.updatedAt = this.$moment(this.$moment.utc(this.orderUpdatedAt).add(20, 'minutes')).diff(this.$moment.utc(), "seconds");
            },
			addToSuitcase() {
				addToSuitcase(this.$route.params.orderId).then(() => {
					this.$router.push({ name: "trips", params: {lang: this.$route.params.lang} })
				})
			},
			toBook() {
				this.$emit("open-dialog", true);

				toBook(this.$route.params.orderId).then(() => {
					this.$router.push({ name: "my-orders" })
				}).catch((err => {
                    this.$emit("get-order");
                    let data = err.response.data;
                    this.$store.commit('notification/errorMessage', {title: data.message})
                })).finally(() => this.$emit("open-dialog", false))
			},
            startTime() {
                this.time = this.updatedAt * 1000;

                this.timer = setInterval(() => {
                    if (this.time <= 0) clearInterval(this.timer);
                    this.time = this.time - 1000;
                }, 1000);
            },
            updateServices(){
                this.loading = true;
                updateServices(this.$route.params.orderId).then(r => {
                    this.$emit('set-order', r.data);
                }).finally(() => this.loading = false)
            },
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-itinerary-info {
		flex-shrink: 0;
		width: 100%;
		@include minw( $grid-breakpoints-sm ) {
			position: sticky;
			position: -webkit-sticky;
			top: 100px;
			z-index: 5;
			width: 390px * .8;
		}
		@include minw( $grid-breakpoints-md ) {
			width: 390px;
		}
		&-wrap {
			@include minw( $grid-breakpoints-sm ) {
				border: 1px solid rgba($black, .1);
				border-radius: $border-radius;
			}
			@include maxw( $grid-breakpoints-sm - 1 ) {
				display: flex;
				flex-direction: column-reverse;
			}
		}
		&-box {
			padding: 15px;
			@include minw( $grid-breakpoints-sm ) {
				padding: 20px * .6 25px * .6;
			}
			@include minw( $grid-breakpoints-md ) {
				padding: 20px 25px;
			}
			@include maxw( $grid-breakpoints-sm - 1 ) {
				display: grid;
				justify-content: center;
				text-align: center;
				margin: 0 -20px;
				box-shadow: 0px 0px 10px rgba(86, 86, 86, 0.1);
			}
			@include maxw( $grid-breakpoints-xs - 1 ) {
				margin: 0 -10px;
			}
            &-row{

                @include maxw( $grid-breakpoints-sm - 1) {
                    justify-content: center;
                    flex-direction: column;
                    &-col{
                        display: flex;
                        justify-content: center;
                        margin-top: 5px;
                    }
                }
            }
		}
        &-action-timer{
            display: flex;
            align-items: center;
            .app-icon {
                flex-shrink: 0;
                margin-right: 5px;
            }
            span {
                font-size: 18px;
                font-weight: 700;
            }
        }
		&-status {
            padding: 14px 15px;
            background-color: $green-persian;
            @include minw($grid-breakpoints-xs) {
                padding: 18px 20px;
            }
            @include maxw($grid-breakpoints-md - 1) {
                margin-top: 15px;
            }

            &-title {
                text-align: center;
                font-size: 16px;
                font-weight: 700;
                color: $white;
                @include minw($grid-breakpoints-xs) {
                    font-size: 18px;
                }
            }
		}
		&-title {
			font-size: 10px;
			font-weight: 400;
			color: rgba($black, .5);
			@include minw( $grid-breakpoints-xs ) {
				font-size: 13px;
				margin-bottom: 10px;
			}
		}
		&-total {
			font-size: 18px;
			font-weight: 700;
			line-height: 1;
			margin-bottom: 5px;
			@include minw( $grid-breakpoints-xs ) {
				font-size: 22px;
				margin-bottom: 10px;
			}
		}
		&-descr {
			font-size: 12px;
			font-weight: 400;
			color: rgba($black, .7);
			@include minw( $grid-breakpoints-xs ) {
				margin-bottom: 2px;
			}
		}
		&-group-buttons {
			display: flex;
			align-items: center;
			margin-top: 15px;
			margin-left: -2px;
			margin-right: -2px;
			@include minw( $grid-breakpoints-xs ) {
				margin-top: 20px;
				margin-left: -8px * .8;
				margin-right: -8px * .8;
			}
			@include minw( $grid-breakpoints-md ) {
				margin-left: -8px;
				margin-right: -8px;
			}
		}
		&-button {
			padding-left: 2px;
			padding-right: 2px;
			@include minw( $grid-breakpoints-xs ) {
				padding-left: 8px * .8;
				padding-right: 8px * .8;
			}
			@include minw( $grid-breakpoints-md ) {
				padding-left: 8px;
				padding-right: 8px;
			}
			&.block {
				width: 100%;
			}
			.app-btn--xxsmall {
				padding: 0 8px;
				font-size: 15px;
				@include maxw( $grid-breakpoints-xs - 1) {
					height: 34px;
					font-size: 14px;
				}
				@include minw( $grid-breakpoints-md ) {
					padding: 0 16px;
				}
			}
		}
	}
</style>
