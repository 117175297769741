<template>
	<div class='a-itinerary-content'>
		<p class='a-itinerary-content-text text-center'>
			<template v-if='order.dateFrom || order.dateTo'>
				<template v-if='order.dateFrom'>{{$moment(order.dateFrom).format("ddd, MMM DD")}}</template>
				<template v-if='order.dateTo'> - {{$moment(order.dateTo).format("ddd, MMM DD")}}</template>
			</template>
			<template v-else>{{$t("account.itinerary.content.name_tour")}}</template>
		<div class='a-itinerary-content-input'>
			<input
				type="text"
				:placeholder='$t("account.itinerary.content.give_this_tour_name")'
				v-model='name'
				@input='$emit("input", name)'
				@change="updateName"
			/>
		</div>

		<p class='a-itinerary-content-text'>{{$t("account.itinerary.timeline.flights.tourists")}}:</p>
		<a-tourist-list :items='order.paxes' />
		<template v-if='noDetailsTourists'>
			<span class='app-link green--text' @click='dialog = true'>{{$t("account.itinerary.content.fill_tourist_data")}}</span>
		</template>

		<a-dialog-tourists
			:items='order.paxes'
			v-model='dialog'
			@close-dialog="dialog = false"
			@update-tourists='$emit("update-tourists")'
			v-if='order.paxes'
		/>

		<div class='a-itinerary-content-wrap' v-if='isCharterFlight'>
			<a-timeline @open-edit='openEdit($event)' :order='order' @update-order="$emit('update-order', $event)" v-if='order.items && order.items.length' />
		</div>
		<div class='a-itinerary-content-wrap' v-else>
			<p class='a-itinerary-content-text text-center'>{{$t("account.itinerary.content.you_can_add_such_services")}}:</p>
			<div class='a-itinerary-content-navigation' :class='{"active": topFixed}'>
				<div class='a-itinerary-content-navigation-list'>
					<div class='a-itinerary-content-navigation-item'
						v-for='(item, i) in items' :key='i'
						@click='openEdit({type: item.id})'
						:class='{"disabled": item.disabled}'
					>
						<div class='a-itinerary-content-navigation-item-content'>
							<v-icon :class='{stroke: item.icon == "flights"}' :icon='`navigation/${item.icon}`' size='20' />
							<span class='mobile-hide'>{{item.name}}</span>
						</div>
					</div>
				</div>
			</div>
			<a-timeline @open-edit='openEdit($event)' :order='order' @update-order="$emit('update-order', $event)" v-if='order.items && order.items.length' />
		</div>

		<trips-edit
			:title='title'
			v-model='edit'
			@input='edit = $event'
		>
			<template>
				<component
					:is="component"
					:edit='true'
					:searchData='searchData'
					:initialTripName='tripName'
					@update='edit = false'
					:hideChooseTourist='true'
				/>
			</template>
		</trips-edit>

		<list-insurance
			v-model='insurance'
			@open-edit='openInsurance($event)'
			:title='$t("account.itinerary.insurance.add_insurance")'
			:descr='$t("account.itinerary.insurance.choose_tourist_insurance")'
			:notCloseDialog='edit_insurance'
			:order='order'
			:items='findTrip("insurance")'
		/>
		<a-edit-insurance
			v-model='edit_insurance'
			:user='item_tourist'
			:order='order'
			@update-order="$emit('update-order', $event)"
			:initialItems='findTrip("insurance")'
		/>

		<list-visa
			v-model='visa'
			@open-edit='openVisa($event)'
			:title='$t("account.itinerary.visa.add_visa")'
			:descr='$t("account.itinerary.visa.choose_tourist_visa")'
			:notCloseDialog='edit_visa'
			:order='order'
			:items='findTrip("visa")'
		/>
		<a-edit-visa
			v-model='edit_visa'
			:user='item_tourist'
			:order='order'
			@update-order="$emit('update-order', $event)"
			:initialItems='findTrip("visa")'
		/>
	</div>
</template>

<script>
	import ATouristList from '@/components/account/ATouristList';
	import ADialogTourists from '@/views/account/itinerary/dialogs/tourists';
	import ATimeline from '@/views/account/itinerary/timeline';
	import TripsEdit from '@/views/account/search/components/edit';
	import AHomeFlights from "@/views/account/home/flights";
	import AHomeHotels from "@/views/account/home/hotels";
	import AHomeTransfers from "@/views/account/home/transfers";
	import AHomeCars from "@/views/account/home/cars";
	import AHomeCharterFlights from "@/views/account/home/charter-flight";
	import AHomeInsurance from "@/views/account/home/insurance";
	import ListInsurance from "@/views/account/itinerary/components/list-insurance";
	import ListVisa from "@/views/account/itinerary/components/list-visa";
	import AEditInsurance from "@/views/account/itinerary/insurance-visa/edit-insurance";
	import AEditVisa from "@/views/account/itinerary/insurance-visa/edit-vise";
	import { updateName } from '@/services/request';
	import { i18n } from '@/plugins/i18n';

	export default {
		data: () => ({
			container: null,
			topFixed: false,
			name: '',
			edit: false,
			title: null,
			component: null,
			searchData: null,
			tripName: null,
			dialog: false,
			insurance: false,
			edit_insurance: false,
			visa: false,
			edit_visa: false,
			item_tourist: {}
		}),
		props: ['name_tour', 'order', 'isCharterFlight'],
		computed: {
			items() {
				return [
					// {id: 'flight', icon: 'flights', name: i18n.t("routes.flights_full")},
					{id: 'hotel', icon: 'hotels', name: i18n.t("routes.hotel")},
					{id: 'transfer', icon: 'transfers', name: i18n.t("routes.transfer")},
					{id: 'insurance', icon: 'insurance', name: i18n.t("routes.insurance")},
					{id: 'visa', icon: 'visa', name: i18n.t("routes.visa")}
				]
			},
			noDetailsTourists() {
				return this.order.paxes ? !this.order.paxes.every(el =>
					el.name
					&& el.surname
					&& el.gender
					&& el.number
					&& el.birthday
					&& el.nationality_code
					&& el.date_expiration
				) : false
			}
		},
		components: {
			ATouristList,
			ADialogTourists,
			ATimeline,
			TripsEdit,
			AHomeFlights,
			AHomeHotels,
			AHomeTransfers,
			AHomeCars,
			AHomeCharterFlights,
			AHomeInsurance,
			ListInsurance,
			ListVisa,
			AEditInsurance,
			AEditVisa
		},
		created() {
			this.name = this.name_tour;
			window.addEventListener("scroll", this._topFixed, false );
		},
		methods: {
			_topFixed() {
				if(!document.querySelector('.a-itinerary-content-navigation')) return;
				this.topFixed = document.querySelector('.a-itinerary-content-navigation').getBoundingClientRect().top <= 100;
			},
			updateName() {
				updateName(this.$route.params.orderId, {name: this.name});
			},
			openEdit({id, type, searchData}) {
				switch (type) {
					case 'flight': {
						this.title = this.$t("account.search.title.flights")
						this.component = 'AHomeFlights';
						this.edit = true;
						this.searchData = {...searchData, ...this.order.baseSearchData, ...(id ? {replaceOfferId: id} : {})};
						this.tripName = this.name ?? this.$t("account.trips.not_specified");
						return true;
					}
					case 'hotel': {
						this.title = this.$t("account.search.title.hotels")
						this.component = 'AHomeHotels';
						this.edit = true;
						this.searchData = {...searchData, ...this.order.baseSearchData, ...(id ? {replaceOfferId: id} : {})};
						this.tripName = this.name ?? this.$t("account.trips.not_specified");
						return true;
					}
					case 'transfer': {
						this.title = this.$t("account.search.title.transfers")
						this.component = 'AHomeTransfers';
						this.edit = true;
						this.searchData = {...searchData, ...this.order.baseSearchData, ...(id ? {replaceOfferId: id} : {})};
						this.tripName = this.name ?? this.$t("account.trips.not_specified");
						return true;
					}
					case 'cars': {
						this.title = this.$t("account.search.title.cars")
						this.component = 'AHomeCars';
						this.edit = true;
						this.searchData = {...searchData, ...this.order.baseSearchData, ...(id ? {replaceOfferId: id} : {})};
						this.tripName = this.name ?? this.$t("account.trips.not_specified");
						return true;
					}
					case 'insurance': {
						if(this.order.items.length === 1 && this.order.items.find(el => el.type === "flight")) {
							this.title = this.$t("account.search.title.insurance")
							this.component = 'AHomeInsurance';
							this.edit = true;
							this.searchData = {...searchData, ...this.order.baseSearchData, ...(id ? {replaceOfferId: id} : {})};
							this.tripName = this.name ?? this.$t("account.trips.not_specified");
						} else {
							this.insurance = true;
						}
						return true;
					}
					case 'visa': {
						this.visa = true;
						return true;
					}
					case 'charterFlight': {
						this.title = this.$t("account.search.title.charter-flight")
						this.component = 'AHomeCharterFlights';
						this.edit = true;
						this.searchData = {...searchData, ...this.order.baseSearchData, ...(id ? {replaceOfferId: id} : {})};
						this.tripName = this.name;
						return true;
					}
					default:
						return false
				}
			},
			openInsurance($event) {
				this.item_tourist = $event;
				this.edit_insurance = true;
			},
			openVisa($event) {
				this.item_tourist = $event;
				this.edit_visa = true;
			},
			findTrip(name) {
				if(!this.order.items) return;

				if(this.order.items.find(el => el.type == name)) {
					switch(name) {
						case 'insurance':
							return this.order.items.find(el => el.type == name).insurances
						case 'visa':
							return this.order.items.find(el => el.type == name).visas
						default:
							return []
					}
				} else {
					return [];
				}
			}
		},
		watch: {
			name_tour() {
				this.name = this.name_tour;
			}
		},
		beforeDestroy() {
			window.removeEventListener("scroll", this._topFixed, false );
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-itinerary {
		&-content {
			padding-top: 40px;
			width: 100%;
			@include minw( $grid-breakpoints-sm ) {
				width: calc(100% - 390px * .8 - 150px * .6);
			}
			@include minw( $grid-breakpoints-md ) {
				width: calc(100% - 390px - 150px * .8);
			}
			@include minw( $grid-breakpoints-lg ) {
				width: calc(100% - 390px - 150px);
			}
			&-text {
				font-size: 13px;
				font-weight: 400;
				color: rgba($black, .5);
				margin-bottom: 10px;
			}
			&-input {
				margin-bottom: 20px;
				@include minw( $grid-breakpoints-xs ) {
					margin-bottom: 50px;
				}
				input {
					padding: 5px 0;
					font-size: 18px;
					font-weight: 700;
					width: 100%;
					text-align: center;
					border-bottom: 2px solid transparent;
					transition: border-bottom $transition;
					@include minw( $grid-breakpoints-xs ) {
						padding: 10px 0;
						font-size: 28px;
					}
					&:hover,
					&:focus {
						border-color: $green;
					}
					&::placeholder {
						color: rgba($black, .25);
					}
					&:focus::placeholder {
						opacity: 0;
						transition: opacity $transition;
					}
				}
			}
			&-wrap {
				margin-top: 35px;
			}
			&-navigation {
				position: sticky;
				position: -webkit-sticky;
				top: 75px;
				z-index: 5;
				background-color: $white;
				border: 1px solid rgba($black, .1);
				border-radius: 62px;
				overflow: hidden;
				@include minw( $grid-breakpoints-sm ) {
					top: 100px;
				}
				&.active {
					border-color: transparent;
					box-shadow: 0px 0px 30px rgba(86, 86, 86, 0.2);
				}
				&-list {
					display: flex;
					justify-content: space-between;
					margin: 0 15px;
					overflow-y: hidden;
					overflow-x: auto;
					@include minw( $grid-breakpoints-xs ) {
						margin: 0 30px;
					}
				}
				&-item {
					font-size: 14px;
					font-weight: 700;
					padding: 16px 0px;
					cursor: pointer;
					&:not(:last-child) {
						padding-right: 15px;
					}
					&-content {
						display: flex;
						align-items: center;
						white-space: nowrap;
						.app-icon {
							flex-shrink: 0;
							color: $green-persian;
							@include minw( $grid-breakpoints-xs ) {
								margin-right: 8px;
							}
							&.stroke {
								svg {
									fill: none;
									stroke: currentColor;
								}
							}
						}
					}
					&.disabled {
						pointer-events: none;
						opacity: .3;
					}
				}
			}
		}
	}
</style>
