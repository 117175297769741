export default {
    methods: {
        timeDiffAbbr(timestamp) {
            let m = Math.floor((timestamp % 36e5) / 6e4);
            let s = Math.floor((timestamp % 6e4) / 1000);

            let minutes = m > 0 ? (m > 9 ? m : `0${m}`) : "00";
            let seconds = s > 0 ? (s > 9 ? s : `0${s}`) : "00";

            return `${minutes}:${seconds}`;
        }
    }
}